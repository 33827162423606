import React, { useEffect } from "react";
import { Link } from "gatsby";
import Layout from "../layouts/zh";
import SEO from "../components/seo";
import $ from "jquery"


const FaqPage = ({ location }) => {

  useEffect(() => {
    expandData()
  }, []);

  const expandData = () => {
    $(".faq__title").on("click", function (e) {

      if (!$(this).hasClass("faq__title--active")) {
        var active = false;
      }
      $(".faq__title").removeClass("faq__title--active");
      if (active == false) {
        $(this).addClass("faq__title--active");
      }
    });
  }

  const specific_date = new Date('2022-01-09');
  const current_date = new Date();

  return (
    <Layout location={location}>
      <SEO title="常见问题 " />

      <div id="contentWrap">
        <div id="index_content" className="admin-setting">
          <div className="faq">
            <h1 className="text--center">常见问题</h1>
            <div className="container container--800">
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_1">
                  什么是阿雅娜会员礼遇？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      阿雅娜会员优享计划，是一项针对科莫多维艾奇洙沙滩度假村。，阿雅娜lako di'a号豪华船宿，巴厘岛阿雅娜度假别墅，巴厘岛阿雅娜赛格拉酒店，巴厘岛阿雅娜度假酒店，巴厘岛阿雅娜林畔酒店，雅加达中诚广场酒店，Delonix Hotel Karawang酒店。,宾客长期的优惠政策.阿雅娜优享计划会员可享受专属礼遇和奖励积分，这些积分可以兑换成难忘的旅行体验，例如免费住宿和参与酒店房型升级服务。
                      </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_12">
                  有哪些酒店参与此活动？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      A：参与的品牌有：巴厘岛阿雅娜度假别墅，巴厘岛阿雅娜赛格拉酒店，巴厘岛阿雅娜度假酒店，巴厘岛阿雅娜林畔酒店，雅加达中诚广场酒店，Delonix Hotel Karawang 酒店。
                      </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_2">
                  我如何注册？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      答：您可以通过我们的网站ayanarewards.com注册。
                      </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_3">
                  加入阿雅娜会员礼遇是否需要费用？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      答：不需要，您可以免费加入该计划。
                      </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_4">
                  我如何赚取积分以及每次预订可以获得多少积分？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    {current_date.getTime() > specific_date.getTime() ? (
                      <>
                        <p className="p--sm">
                            答：在我们的参与计划酒店每消费10,000印尼盾即可获得1积分（在加拉璜凤凰木酒店每消费15,000印尼盾则可获得1积分），不含税金和服务费。您的积分将自动计入您的帐户。阿雅娜会员计划金卡会员每消费10,000印尼盾将获得额外10％的奖励积分，铂金卡会员将获得额外25％的奖励积分。如欲了解更多有关我们参与酒店的积分标准, 请参阅我们的
                            <strong>
                            <u>完整条款和条件</u>
                          </strong>
                            ：
                            <span
                            style={{
                              fontSize: "15px",
                              fontVariantLigatures: "common-ligatures",
                              color: "rgb(0, 0, 0)",
                            }}>
                            <Link to="/zh/terms-and-conditions-8"
                              style={{
                                fontSize: "15px",
                                fontVariantLigatures: "common-ligatures",
                              }}>
                              {`${location.origin}/terms-and-conditions-8`}
                            </Link>
                              &nbsp;
                            </span>
                            。
                        </p>
                      </>
                      ) : (
                      <>
                        <p className="p--sm">
                          答：在我们的参与酒店每消费1万印尼盾即可获得1积分。您的积分将自动计入您的帐户。阿雅娜会员礼遇金卡会员每消费1万印尼盾将获得额外10％的奖励积分，白金卡会员将获得额外15％的奖励积分。更多关于参与酒店以及资质信息，请参阅我们的
                            <strong>
                            <u>完整条款和条件</u>
                          </strong>
                            ：
                            <span
                            style={{
                              fontSize: "15px",
                              fontVariantLigatures: "common-ligatures",
                              color: "rgb(0, 0, 0)",
                            }}>
                            <Link to="/zh/terms-and-conditions-8"
                              style={{
                                fontSize: "15px",
                                fontVariantLigatures: "common-ligatures",
                              }}>
                              {`${location.origin}/terms-and-conditions-8`}
                            </Link>
                              &nbsp;
                            </span>
                            。
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_5">
                  每次入住参与计划酒店最多可赚取几间客房的积分？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      {current_date.getTime() > specific_date.getTime() ? (
                          <>
                          答：满足下列条件的会员在参与计划酒店同入住日期间可赚取多达5间房间的积分：（1）每一间房间由该会员在退房时支付所有费用（2）并且入住预订的其中一间客房。
                          </>
                          ) : (
                          <>
                          答：满足下列条件的会员在参与计划酒店同入住日期间可赚取多达5间房间的积分：（1）每一间房间由该会员在退房时支付所有费用（2）并且亲自入住预订的其中一间客房。
                          </>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_6">
                  如果没有入住以我名义下预订的房间我是否可获取点数？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      答：我们的会员计划规定，仅在酒店入住的会员可获取点数。
                      </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_7">
                  我如何以及何时能兑换积分？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      {current_date.getTime() > specific_date.getTime() ? (
                          <>
                          答：您可以通过ayanarewards.com登录您的帐户查看兑换页面中的奖励目录，兑换您最喜爱的生活方式和旅行体验。
                          </>
                          ) : (
                          <>
                          答：您可以通过ayanarewards.com登录您的帐户查看奖励目录，并兑换您最喜爱的生活方式和旅行体验。
                          </>
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_8">
                  为何我不能兑换积分？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      答：在某些情况下，您无法兑换积分： a.
                      您没有足够积分来兑换您选择的奖励。在这种情况下，请选择需要较少积分的其他奖励或赚取更多积分后再兑换。
                      b. 您的积分已过期。 c.
                      您选择的奖励已全部兑换完毕。在这种情况下，网站通常会通知您该奖励的可兑换性。
                      </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_9">
                  奖励积分需要多长时间计入我的帐户？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      答：
                        <span
                        lang="ZH-CN"
                        style={{
                          fontSize: "11pt",
                          fontFamily: "PMingLiU, serif",
                        }}>
                        积分将在结账离店的
                        </span>
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: "Calibri, sans-serif",
                        }}>
                        7
                        </span>
                      <span
                        lang="ZH-CN"
                        new=""
                        style={{ fontSize: "11pt", fontFamily: "" }}
                        times="">
                        个工作日内到
                        </span>
                      <span
                        lang="ZH-CN"
                        style={{
                          fontSize: "11pt",
                          fontFamily: "PMingLiU, serif",
                        }}>
                        账
                        </span>
                        。
                      </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_10">
                  我的阿雅娜会员礼遇积分有效期为多久？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      答：积分有效期为10年。
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_11">
                  如何查看我的积分余额、交易和兑换历史？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      {current_date.getTime() > specific_date.getTime() ? (
                          <>
                          答：您可以登录ayanarewards.com访问个人信息查看积分详情。
                          </>
                          ) : (
                          <>
                          答：您可以登录ayanarewards.com查看所有详细信息。
                          </>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FaqPage;
